import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { FaGoogle, FaSignOutAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
import logo from "../assets/images/logo.png";

function Header() {
  const { user, signInWithGoogle, logout } = useAuth();
  return (
    <header className="w-full flex flex-col sm:flex-row justify-between items-center p-4 bg-gray-800 shadow-lg">
      <Helmet>
        <title>FaceitReload - Track Your Faceit Friends Effortlessly</title>
        <meta
          name="description"
          content="Join the community, compete with friends, and climb the leaderboards on FaceitReload. Create leagues, earn emoji prizes, and more!"
        />
        <meta
          name="keywords"
          content="FaceitReload, Faceit tracking, gaming leagues, leaderboards, emoji prizes, gaming community"
        />
        <meta name="author" content="FaceitReload Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="FaceitReload - Track Your Faceit Friends Effortlessly"
        />
        <meta
          property="og:description"
          content="Join the community, compete with friends, and climb the leaderboards on FaceitReload."
        />
        <meta
          property="og:image"
          content="https://faceitreload.com/assets/images/og-image.jpg"
        />
        <meta property="og:url" content="https://faceit.al" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta
          name="twitter:title"
          content="FaceitReload - Track Your Faceit Friends Effortlessly"
        />
        <meta
          name="twitter:description"
          content="Join the community, compete with friends, and climb the leaderboards on FaceitReload."
        />
        <meta
          name="twitter:image"
          content="https://faceitreload.com/assets/images/twitter-card.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "FaceitReload",
              "url": "https://faceit.al",
              "logo": "https://faceit.al/assets/images/logo.png",
              "sameAs": [
                "https://www.facebook.com/faceitreload",
                "https://www.twitter.com/faceitreload",
                "https://www.instagram.com/faceitreload"
              ],
              "description": "Join the community, compete with friends, and climb the leaderboards on FaceitReload."
            }
          `}
        </script>
      </Helmet>

      <Link to="/" className="flex items-center mb-4 sm:mb-0">
        <img
          src={logo}
          alt="FaceitReload Logo"
          className="w-8 h-8 sm:w-10 sm:h-10 mr-2"
        />
        <h1 className="text-xl sm:text-2xl font-bold text-white">
          FaceitReload
        </h1>
      </Link>

      {user ? (
        <nav className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 text-white">
          <span className="text-sm sm:text-base">
            Logged in as {user.email}
          </span>
          <Link
            to="/dashboard"
            className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-md text-sm sm:text-base font-semibold transition duration-200 w-full sm:w-auto text-center"
          >
            Admin Panel
          </Link>
          <button
            onClick={logout}
            className="flex items-center justify-center bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md font-semibold text-sm sm:text-base transition duration-200 w-full sm:w-auto"
          >
            <FaSignOutAlt className="mr-2" />
            Logout
          </button>
        </nav>
      ) : (
        <button
          onClick={signInWithGoogle}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md font-semibold flex items-center justify-center gap-2 transition duration-200 text-sm sm:text-base w-full sm:w-auto"
        >
          <FaGoogle className="w-5 h-5" />
          Sign in with Google
        </button>
      )}
    </header>
  );
}

export default Header;

// src/pages/Dashboard.js
import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { leagueService } from "../services/leagueService";
import { faceitService } from "../services/faceitService"; // Import faceitService
import { isOnlyEmoji, defaultPrizes } from "../utils/emojiValidator";
import { FaEllipsisV } from "react-icons/fa";
import Header from "../components/Header";

function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingLeague, setEditingLeague] = useState(null);
  const [formData, setFormData] = useState({ name: "" });

  // Create a reference for the input
  const inputRef = useRef(null);

  const loadLeagues = useCallback(async () => {
    try {
      const userLeagues = await leagueService.getUserLeagues(user.uid);
      setLeagues(userLeagues);
    } catch (error) {
      console.error("Error loading leagues:", error);
    } finally {
      setLoading(false);
    }
  }, [user.uid]);

  useEffect(() => {
    loadLeagues();
  }, [loadLeagues]);

  // Focus on input field when modal opens
  useEffect(() => {
    if (showCreateModal || editingLeague) {
      inputRef.current?.focus();
    }
  }, [showCreateModal, editingLeague]);

  const handleCreateLeague = async (e) => {
    e.preventDefault();
    try {
      await leagueService.createLeague(user.uid, formData);
      setShowCreateModal(false);
      setFormData({ name: "" });
      // Reload leagues after creating a new one
      await loadLeagues();
    } catch (error) {
      console.error("Error creating league:", error);
    }
  };

  const handleUpdateLeague = async (e) => {
    e.preventDefault();
    try {
      await leagueService.updateLeague(editingLeague.id, formData);
      setEditingLeague(null);
      setFormData({ name: "" });
      setLeagues((prevLeagues) =>
        prevLeagues.map((league) =>
          league.id === editingLeague.id
            ? { ...league, name: formData.name }
            : league
        )
      );
    } catch (error) {
      console.error("Error updating league:", error);
    }
  };

  const handleDeleteLeague = async (leagueId) => {
    if (window.confirm("Are you sure you want to delete this league?")) {
      try {
        await leagueService.deleteLeague(leagueId);
        setLeagues((prevLeagues) =>
          prevLeagues.filter((league) => league.id !== leagueId)
        );
      } catch (error) {
        console.error("Error deleting league:", error);
      }
    }
  };

  const copyLeagueId = async (leagueId) => {
    try {
      await navigator.clipboard.writeText(leagueId);
      alert("League ID copied to clipboard!");
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <div className="max-w-7xl mx-auto py-10">
        {/* Content */}
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Create New League Card */}
            <div
              onClick={() => setShowCreateModal(true)}
              className="flex items-center justify-center bg-gray-800 rounded-lg shadow-md p-6 cursor-pointer hover:bg-gray-700"
              isCreateLeagueCard
            >
              <div className="text-center">
                <div className="text-5xl mb-4">+</div>
                <h3 className="text-xl font-bold">Create New League</h3>
              </div>
            </div>

            {leagues.map((league) => (
              <LeagueCard
                key={league.id}
                league={league}
                onEdit={() => {
                  setEditingLeague(league);
                  setFormData({ name: league.name });
                }}
                onDelete={() => handleDeleteLeague(league.id)}
                onCopyId={() => copyLeagueId(league.id)}
                onView={() => navigate(`/league/${league.id}`)}
                onPlayerUpdate={(updatedLeague) => {
                  setLeagues(
                    leagues.map((l) =>
                      l.id === updatedLeague.id ? updatedLeague : l
                    )
                  );
                }}
              />
            ))}
          </div>
        )}

        {/* Create/Edit Modal */}
        {(showCreateModal || editingLeague) && (
          <Modal
            onClose={() => {
              setShowCreateModal(false);
              setEditingLeague(null);
              setFormData({ name: "" });
            }}
          >
            <form
              onSubmit={editingLeague ? handleUpdateLeague : handleCreateLeague}
            >
              <h2 className="text-2xl font-bold mb-6">
                {editingLeague ? "Edit League" : "Create New League"}
              </h2>
              <div className="mb-4">
                <label className="block text-gray-300 text-sm font-semibold mb-2">
                  League Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  className="w-full px-4 py-2 rounded-lg bg-gray-950 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  ref={inputRef} // Attach ref to input
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg"
                >
                  {editingLeague ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </div>
  );
}

function LeagueCard({
  league,
  onEdit,
  onDelete,
  onCopyId,
  onView,
  onPlayerUpdate,
}) {
  const [editingPrizes, setEditingPrizes] = useState(false);
  const [prizes, setPrizes] = useState(league.prizes || defaultPrizes);
  const [prizeError, setPrizeError] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setPrizes(league.prizes || defaultPrizes);
  }, [league.prizes]);

  const handlePrizeChange = (position, value) => {
    if (value && !isOnlyEmoji(value)) {
      setPrizeError("Only emoji characters are allowed");
      return;
    }
    setPrizeError("");
    setPrizes({ ...prizes, [position]: value });
  };

  const handlePrizeSave = async () => {
    try {
      const updatedLeague = await leagueService.updatePrizes(league.id, prizes);
      onPlayerUpdate(updatedLeague);
      setEditingPrizes(false);
      setPrizeError("");
    } catch (error) {
      console.error("Error updating prizes:", error);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow-md p-6 relative">
      <div className="absolute top-4 right-4">
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="text-gray-400 hover:text-gray-200 focus:outline-none"
        >
          <FaEllipsisV />
        </button>
        {showMenu && (
          <div className="absolute right-0 mt-2 w-40 bg-gray-700 rounded-md shadow-lg z-10">
            <button
              onClick={() => {
                setShowMenu(false);
                onEdit();
              }}
              className="block px-4 py-2 text-sm text-white hover:bg-gray-600 w-full text-left"
            >
              Edit
            </button>
            <button
              onClick={() => {
                setShowMenu(false);
                onDelete();
              }}
              className="block px-4 py-2 text-sm text-white hover:bg-gray-600 w-full text-left"
            >
              Delete
            </button>
            <button
              onClick={() => {
                setShowMenu(false);
                onCopyId();
              }}
              className="block px-4 py-2 text-sm text-white hover:bg-gray-600 w-full text-left"
            >
              Share
            </button>
          </div>
        )}
      </div>

      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-xl font-bold">{league.name}</h3>
          <p className="text-sm text-gray-400 mt-1">ID: {league.id}</p>
        </div>
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-semibold">Prizes</h4>
          <button
            onClick={() => setEditingPrizes(!editingPrizes)}
            className="text-blue-400 hover:text-blue-500 text-sm"
          >
            {editingPrizes ? "Cancel" : "Edit Prizes"}
          </button>
        </div>

        {editingPrizes ? (
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <label className="text-sm w-20">1st Place:</label>
              <input
                type="text"
                value={prizes.first}
                onChange={(e) => handlePrizeChange("first", e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-gray-950 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={2}
              />
            </div>
            <div className="flex items-center space-x-2">
              <label className="text-sm w-20">2nd Place:</label>
              <input
                type="text"
                value={prizes.second}
                onChange={(e) => handlePrizeChange("second", e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-gray-950 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={2}
              />
            </div>
            <div className="flex items-center space-x-2">
              <label className="text-sm w-20">3rd Place:</label>
              <input
                type="text"
                value={prizes.third}
                onChange={(e) => handlePrizeChange("third", e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-gray-950 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={2}
              />
            </div>
            {prizeError && <p className="text-red-500 text-sm">{prizeError}</p>}
            <button
              onClick={handlePrizeSave}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-lg text-sm mt-2"
              disabled={!!prizeError}
            >
              Save Prizes
            </button>
          </div>
        ) : (
          <div className="flex space-x-4">
            <span className="text-lg">{prizes.first} 1st</span>
            <span className="text-lg">{prizes.second} 2nd</span>
            <span className="text-lg">{prizes.third} 3rd</span>
          </div>
        )}
      </div>

      <div className="mb-6">
        <h4 className="font-semibold mb-2">
          Players ({league.players?.length || 0})
        </h4>
        <PlayersList league={league} onPlayerUpdate={onPlayerUpdate} />
      </div>

      <div className="flex justify-end mt-6">
        <button
          onClick={onView}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg text-sm"
        >
          View League
        </button>
      </div>
    </div>
  );
}

function PlayersList({ league, onPlayerUpdate }) {
  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [newPlayer, setNewPlayer] = useState("");
  const [addPlayerMessage, setAddPlayerMessage] = useState(""); // New state for messages
  const [addPlayerError, setAddPlayerError] = useState(""); // New state for errors

  const handleAddPlayer = async (e) => {
    e.preventDefault();
    setAddPlayerError("");
    setAddPlayerMessage("");
    try {
      // Check if player exists on Faceit
      const playerDetails = await faceitService.getPlayerDetails(
        newPlayer.trim()
      );
      if (!playerDetails) {
        setAddPlayerError(
          "Player not found. Please check the nickname and try again."
        );
        return;
      }
      // Player exists, proceed to add
      await leagueService.addPlayer(league.id, newPlayer.trim());
      const updatedLeague = {
        ...league,
        players: [...(league.players || []), newPlayer.trim()],
      };
      onPlayerUpdate(updatedLeague);
      setAddPlayerMessage("Player found and added successfully.");
      setNewPlayer("");
      setShowAddPlayer(false);
    } catch (error) {
      console.error("Error adding player:", error);
      setAddPlayerError("An error occurred while adding the player.");
    }
  };

  const handleRemovePlayer = async (player) => {
    try {
      await leagueService.removePlayer(league.id, player);
      const updatedLeague = {
        ...league,
        players: (league.players || []).filter((p) => p !== player),
      };
      onPlayerUpdate(updatedLeague);
    } catch (error) {
      console.error("Error removing player:", error);
    }
  };

  return (
    <div>
      <ul className="space-y-2">
        {(league.players || []).map((player, index) => (
          <li key={index} className="flex justify-between items-center">
            <span>{player}</span>
            <button
              onClick={() => handleRemovePlayer(player)}
              className="text-red-400 hover:text-red-500 text-sm"
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
      {showAddPlayer ? (
        <form onSubmit={handleAddPlayer} className="mt-4">
          <input
            type="text"
            value={newPlayer}
            onChange={(e) => setNewPlayer(e.target.value)}
            className="w-full bg-gray-700 text-white border border-gray-600 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter player nickname"
            required
          />
          <div className="mt-2 flex space-x-2">
            <button
              type="submit"
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-1 px-4 rounded-lg text-sm"
            >
              Add
            </button>
            <button
              type="button"
              onClick={() => {
                setShowAddPlayer(false);
                setAddPlayerError("");
                setAddPlayerMessage("");
                setNewPlayer("");
              }}
              className="bg-gray-600 hover:bg-gray-700 text-white font-semibold py-1 px-4 rounded-lg text-sm"
            >
              Cancel
            </button>
          </div>
          {addPlayerMessage && (
            <p className="text-green-500 text-sm mt-2">{addPlayerMessage}</p>
          )}
          {addPlayerError && (
            <p className="text-red-500 text-sm mt-2">{addPlayerError}</p>
          )}
        </form>
      ) : (
        <button
          onClick={() => setShowAddPlayer(true)}
          className="mt-4 text-blue-400 hover:text-blue-500 text-sm"
        >
          + Add Player
        </button>
      )}
    </div>
  );
}

function Modal({ children, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
      <div className="bg-gray-800 text-white rounded-lg p-8 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-200 text-2xl focus:outline-none"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
}

export default Dashboard;

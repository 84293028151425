import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { leagueService } from "../services/leagueService";
import {
  FaTrophy,
  FaSmile,
  FaChartLine,
  FaSadTear,
  FaSearch,
} from "react-icons/fa";
import Header from "../components/Header";
import { faceitService } from "../services/faceitService";
import avatarFallback from "../assets/images/avatar-fallback.png";

function Login() {
  const { user, signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchError, setSearchError] = useState("");
  const [recentLeagues, setRecentLeagues] = useState([]);
  const [favoriteLeagues, setFavoriteLeagues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRecentLeagues();
    loadFavoriteLeagues();
  }, []);

  const fetchRecentLeagues = async () => {
    try {
      const leagues = await leagueService.getRecentLeagues();

      // Filter out leagues with fewer than 1 player
      const validLeagues = leagues.filter(
        (league) => league.players.length >= 1
      );

      // Fetch avatars for each player in each league
      const leaguesWithAvatars = await Promise.all(
        validLeagues.map(async (league) => {
          const playersWithAvatars = await Promise.all(
            league.players.map(async (playerNickname) => {
              try {
                const playerDetails = await faceitService.getPlayerDetails(
                  playerNickname
                );

                // Ensure avatar URL uses HTTPS
                const avatarUrl =
                  playerDetails && playerDetails.avatar
                    ? playerDetails.avatar.replace(/^http:\/\//i, "https://")
                    : avatarFallback;

                return {
                  nickname: playerNickname,
                  avatarUrl,
                };
              } catch (error) {
                console.error(
                  `Error fetching avatar for player ${playerNickname}:`,
                  error
                );
                return {
                  nickname: playerNickname,
                  avatarUrl: avatarFallback,
                };
              }
            })
          );
          return { ...league, players: playersWithAvatars };
        })
      );

      setRecentLeagues(leaguesWithAvatars);
    } catch (error) {
      console.error("Error fetching recent leagues:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadFavoriteLeagues = async () => {
    const favorites = JSON.parse(localStorage.getItem("favoriteLeagues")) || [];
    const leagues = [];
    for (const leagueId of favorites) {
      try {
        const league = await leagueService.getLeague(leagueId);
        if (league && league.players.length >= 1) {
          leagues.push(league);
        }
      } catch (error) {
        console.error("Error loading favorite league:", error);
      }
    }
    setFavoriteLeagues(leagues);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchError("");

    if (!searchQuery.trim()) {
      setSearchError("Please enter a league ID");
      return;
    }

    try {
      const league = await leagueService.getLeague(searchQuery.trim());
      if (league) {
        navigate(`/league/${searchQuery.trim()}`);
        setSearchQuery("");
      } else {
        setSearchError("League not found");
      }
    } catch (error) {
      setSearchError("Error finding league");
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center">
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <section
        className="w-full bg-cover bg-center py-20"
        style={{ backgroundImage: "url('/hero-bg.jpg')" }}
      >
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Track Your Faceit Friends Effortlessly
          </h1>
          <p className="text-xl text-gray-200 mb-8">
            Join the community, compete with friends, and climb the
            leaderboards.
          </p>
          <button
            onClick={() => (user ? navigate("/dashboard") : signInWithGoogle())}
            className="bg-orange-500 hover:bg-orange-600 text-white px-8 py-4 rounded-full font-semibold text-xl transition duration-200"
          >
            Create a League Now
          </button>
        </div>
      </section>

      {/* How It Works Section */}
      <div className="mt-16 mb-24 px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-100">
          How It Works
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: <FaTrophy className="w-12 h-12 text-orange-500 mb-4" />,
              title: "League Tracking",
              description: "Sign in, create, and track leagues easily.",
            },
            {
              icon: <FaSmile className="w-12 h-12 text-yellow-400 mb-4" />,
              title: "Emoji Prizes",
              description: "Earn emojis as prizes for bragging rights.",
            },
            {
              icon: <FaChartLine className="w-12 h-12 text-green-500 mb-4" />,
              title: "Point System",
              description: "Win = 12 pts, Loss = 8 pts. Keep climbing!",
            },
            {
              icon: <FaSadTear className="w-12 h-12 text-blue-500 mb-4" />,
              title: "Hall of Shame",
              description: "Lowest scorer gets a special mention.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-gray-800 p-8 rounded-2xl text-center shadow-lg hover:shadow-2xl transition duration-300"
            >
              {item.icon}
              <h3 className="font-semibold text-2xl mb-4 text-gray-200">
                {item.title}
              </h3>
              <p className="text-md text-gray-400">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Search and Leagues Section */}
      <div className="w-full max-w-5xl grid grid-cols-1 lg:grid-cols-2 gap-12 px-4 mb-24">
        {/* Search and Recent Leagues Column */}
        <div className="flex flex-col items-center bg-gray-800 p-8 rounded-xl shadow-lg w-full">
          <h2 className="text-2xl font-semibold mb-6">Search for Leagues</h2>
          <form onSubmit={handleSearch} className="flex flex-col gap-4 w-full">
            <div className="relative w-full">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Enter League ID..."
                className="w-full px-5 py-4 rounded-xl bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <FaSearch className="absolute right-4 top-4 text-gray-400" />
            </div>
            {searchError && (
              <p className="text-red-500 text-sm text-center">{searchError}</p>
            )}
            <button
              type="submit"
              className="bg-orange-500 hover:bg-orange-600 text-white px-6 py-3 rounded-full font-semibold transition duration-200 w-full"
            >
              Search League
            </button>
          </form>

          {/* List of Recent Leagues */}
          <h3 className="text-xl font-semibold mt-10 mb-4 text-gray-100">
            Recent Leagues
          </h3>
          {loading ? (
            <div className="text-center">Loading...</div>
          ) : recentLeagues.length > 0 ? (
            <div className="w-full space-y-4">
              {recentLeagues.map((league) => (
                <div
                  key={league.id}
                  className="bg-gray-700 p-4 rounded-lg shadow-md cursor-pointer transition duration-200 hover:bg-gray-600 w-full"
                  onClick={() => navigate(`/league/${league.id}`)}
                >
                  <h4 className="text-lg font-bold mb-2">{league.name}</h4>
                  <div className="flex -space-x-3 overflow-hidden">
                    {league.players.slice(0, 9).map((player, index) => (
                      <div key={index} className="relative group flex-shrink-0">
                        <img
                          src={player.avatarUrl}
                          alt={player.nickname}
                          className="inline-block h-12 w-12 rounded-full"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = avatarFallback;
                          }}
                        />
                        {/* Tooltip with refined styling */}
                        <span className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 opacity-0 group-hover:opacity-100 bg-black text-white text-xs rounded px-2 py-1 whitespace-nowrap transition-opacity duration-200 z-10">
                          {player.nickname}
                        </span>
                      </div>
                    ))}

                    {league.players.length > 9 && (
                      <div className="z-0 h-12 w-12 rounded-full bg-gray-800 flex items-center justify-center text-gray-300 font-bold">
                        +{league.players.length - 9}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-400">No recent leagues available.</p>
          )}
        </div>

        {/* Favorite Leagues Column */}
        <div className="flex flex-col items-center w-full">
          <h2 className="text-2xl font-semibold mb-6">My Favorited Leagues</h2>
          {favoriteLeagues.length > 0 ? (
            <div className="w-full space-y-6">
              {favoriteLeagues.map((league) => (
                <div
                  key={league.id}
                  className="bg-gray-800 p-6 rounded-2xl shadow-lg hover:shadow-2xl transition duration-300 cursor-pointer w-full"
                  onClick={() => navigate(`/league/${league.id}`)}
                >
                  <h3 className="text-xl font-bold mb-2">{league.name}</h3>
                  <p className="text-gray-400">
                    Players: {league.players.length}
                  </p>
                  <p className="text-gray-500">
                    Created on:{" "}
                    {new Date(league.createdAt).toLocaleDateString()}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-400">No favorite leagues added.</p>
          )}
        </div>
      </div>

      {/* Footer */}
      <footer className="w-full bg-gray-800 py-6">
        <div className="max-w-7xl mx-auto text-center text-gray-400">
          © {new Date().getFullYear()} FaceitReload. All rights reserved.
          Contact
        </div>
      </footer>
    </div>
  );
}

export default Login;

import React, { useEffect, useState, useCallback } from "react";
import { faceitService } from "../services/faceitService";

const LiveGames = ({ playersData }) => {
  const [recentMatches, setRecentMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRecentMatches = useCallback(async () => {
    if (!playersData || playersData.length === 0) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const allMatchesMap = new Map();

      const playersRecentMatches = await Promise.all(
        playersData.map((player) =>
          faceitService.getPlayerRecentMatches(player.playerId, 40)
        )
      );

      playersRecentMatches.forEach((matches) => {
        matches.forEach((match) => {
          if (!allMatchesMap.has(match.match_id)) {
            allMatchesMap.set(match.match_id, match);
          }
        });
      });

      const allMatchesArray = Array.from(allMatchesMap.values());
      allMatchesArray.sort((a, b) => b.started_at - a.started_at);

      const latestMatches = allMatchesArray.slice(0, 30);

      const matchDetailsList = await Promise.all(
        latestMatches.map((match) =>
          faceitService.getMatchDetails(match.match_id)
        )
      );

      setRecentMatches(matchDetailsList);
    } catch (error) {
      console.error("Error fetching recent matches:", error);
    } finally {
      setLoading(false);
    }
  }, [playersData]);

  useEffect(() => {
    fetchRecentMatches();
  }, [fetchRecentMatches]);

  const isRankedPlayer = (nickname) => {
    return playersData.some((player) => player.nickname === nickname);
  };

  if (loading) {
    return (
      <div className="text-center text-gray-400 py-12">
        Loading recent matches...
      </div>
    );
  }

  if (recentMatches.length === 0) {
    return (
      <div className="text-center text-gray-400 py-12">
        No recent matches to display.
      </div>
    );
  }

  return (
    <div className="bg-gray-800 rounded-lg">
      <h2 className="text-xl font-bold mb-4">Recent Matches</h2>
      <div className="flex flex-col space-y-4">
        {recentMatches.map((match) => {
          // Get map details and image for the selected map
          const selectedMap = match.voting?.map?.entities?.find(
            (map) => map.game_map_id === match.voting?.map?.pick?.[0]
          );
          const mapName = selectedMap?.name || "Map TBD";
          const mapImage = selectedMap?.image_lg || selectedMap?.image_sm;

          return (
            <div
              key={match.match_id}
              className="flex flex-col md:flex-row md:justify-between items-center border-b border-gray-600 p-4 cursor-pointer hover:bg-gray-700 rounded"
              onClick={() =>
                window.open(
                  `https://www.faceit.com/en/cs2/room/${match.match_id}`,
                  "_blank"
                )
              }
            >
              {/* Desktop View */}
              <div className="hidden md:flex justify-between items-center w-full">
                {/* Team 1 */}
                <div className="flex items-center basis-[35%]">
                  <img
                    src={match.teams.faction1.avatar || ""}
                    alt={match.teams.faction1.name}
                    className="w-10 h-10 rounded-full mr-2"
                  />
                  <div>
                    <span className="font-bold text-white">
                      {match.teams.faction1.name}
                    </span>
                    <div className="text-gray-400 text-xs">
                      {match.teams.faction1.roster
                        .map((player) => (
                          <span
                            key={player.nickname}
                            className={
                              isRankedPlayer(player.nickname)
                                ? "text-orange-500 font-bold"
                                : ""
                            }
                          >
                            {player.nickname}
                          </span>
                        ))
                        .reduce((prev, curr) => [prev, ", ", curr])}
                    </div>
                  </div>
                </div>

                {/* Match Info */}
                <div className="text-center basis-[20%]">
                  <div className="font-bold text-lg">
                    {match.results ? (
                      <>
                        <span
                          className={
                            match.results.score.faction1 >
                            match.results.score.faction2
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {match.results.score.faction1 >
                          match.results.score.faction2
                            ? "W"
                            : "L"}
                        </span>
                        {" : "}
                        <span
                          className={
                            match.results.score.faction2 >
                            match.results.score.faction1
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {match.results.score.faction2 >
                          match.results.score.faction1
                            ? "W"
                            : "L"}
                        </span>
                      </>
                    ) : (
                      "VS"
                    )}
                  </div>
                  <div className="text-xs text-gray-500 mt-2">
                    {new Date(match.started_at * 1000).toLocaleString(
                      undefined,
                      {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                  </div>
                </div>

                {/* Team 2 */}
                <div className="flex items-center basis-[35%] justify-left">
                  <img
                    src={match.teams.faction2.avatar || ""}
                    alt={match.teams.faction2.name}
                    className="w-10 h-10 rounded-full mr-2"
                  />
                  <div>
                    <span className="font-bold text-white">
                      {match.teams.faction2.name}
                    </span>
                    <div className="text-gray-400 text-xs">
                      {match.teams.faction2.roster
                        .map((player) => (
                          <span
                            key={player.nickname}
                            className={
                              isRankedPlayer(player.nickname)
                                ? "text-orange-500 font-bold"
                                : ""
                            }
                          >
                            {player.nickname}
                          </span>
                        ))
                        .reduce((prev, curr) => [prev, ", ", curr])}
                    </div>
                  </div>
                </div>

                {/* Map Image and Text */}
                <div className="text-center basis-[10%]">
                  {mapImage && (
                    <div className="relative inline-block">
                      <img
                        src={mapImage}
                        alt={mapName}
                        className="w-20 h-14 mt-1 rounded"
                      />
                      <div className="absolute inset-6 flex items-start justify-center">
                        <div className="bg-white bg-opacity-30 text-gray-950 font-bold text-xs px-1">
                          {mapName}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Mobile View */}
              <div className="flex md:hidden flex-col w-full space-y-5">
                {/* Faction 1 */}
                <div>
                  <div className="flex justify-between w-full">
                    <span className="font-bold text-white">
                      {match.teams.faction1.name}
                    </span>
                    <div className="text-lg font-bold">
                      {match.results ? (
                        <span
                          className={
                            match.results.score.faction1 >
                            match.results.score.faction2
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {match.results.score.faction1 >
                          match.results.score.faction2
                            ? "W"
                            : "L"}
                        </span>
                      ) : (
                        "VS"
                      )}
                    </div>
                  </div>
                  <div className="text-orange-500 text-xs">
                    {match.teams.faction1.roster
                      .filter((player) => isRankedPlayer(player.nickname))
                      .map((player) => player.nickname)
                      .join(", ")}
                  </div>
                </div>

                {/* Faction 2 */}
                <div>
                  <div className="flex justify-between w-full">
                    <span className="font-bold text-white">
                      {match.teams.faction2.name}
                    </span>
                    <div className="text-lg font-bold">
                      {match.results ? (
                        <span
                          className={
                            match.results.score.faction2 >
                            match.results.score.faction1
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {match.results.score.faction2 >
                          match.results.score.faction1
                            ? "W"
                            : "L"}
                        </span>
                      ) : (
                        "VS"
                      )}
                    </div>
                  </div>
                  <div className="text-orange-500 font-bold text-xs">
                    {match.teams.faction2.roster
                      .filter((player) => isRankedPlayer(player.nickname))
                      .map((player) => player.nickname)
                      .join(", ")}
                  </div>
                </div>

                {/* Additional Info */}
                <div className="text-xs text-gray-500 mt-5">{mapName}</div>
                <div className="text-xs text-gray-500">
                  {new Date(match.started_at * 1000).toLocaleString()}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LiveGames;

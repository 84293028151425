// src/utils/emojiValidator.js
export const isOnlyEmoji = (str) => {
  const emojiRegex = /^[\p{Emoji}]$/u;
  return emojiRegex.test(str);
};

export const defaultPrizes = {
  first: "🥇",
  second: "🥈",
  third: "🥉",
  last: "💩",
};

// src/components/HallOfShamePlayer.js
import React, { useEffect, useRef, useState, useCallback } from "react";
import gaySound from "../assets/sounds/gay.mp3";
import level1 from "../assets/images/1.png";
import level2 from "../assets/images/2.png";
import level3 from "../assets/images/3.png";
import level4 from "../assets/images/4.png";
import level5 from "../assets/images/5.png";
import level6 from "../assets/images/6.png";
import level7 from "../assets/images/7.png";
import level8 from "../assets/images/8.png";
import level9 from "../assets/images/9.png";
import level10 from "../assets/images/10.png";

const levelImages = {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
  7: level7,
  8: level8,
  9: level9,
  10: level10,
};

function HallOfShamePlayer({ player }) {
  const audioRef = useRef(new Audio(gaySound));
  const intervalRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playSound = useCallback(async () => {
    try {
      if (!isPlaying) {
        setIsPlaying(true);
        await audioRef.current.play();
        audioRef.current.addEventListener("ended", () => {
          setIsPlaying(false);
        });
      }
    } catch (error) {
      console.log("Audio playback error:", error);
      setIsPlaying(false);
    }
  }, [isPlaying]);

  useEffect(() => {
    // Store reference to audio at the start of the effect
    const audio = audioRef.current;

    // Play sound immediately when component mounts
    playSound();

    // Start playing sound every 10 seconds
    intervalRef.current = setInterval(playSound, 10000);

    // Cleanup function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
        audio.removeEventListener("ended", () => setIsPlaying(false));
      }
      setIsPlaying(false);
    };
  }, [playSound]);
  return (
    <div className="bg-gray-800 rounded-lg p-6">
      <div className="flex flex-col items-center space-y-4">
        {/* Player Header */}
        <div className="flex flex-col items-center space-y-2">
          <img
            src={player.avatarUrl}
            alt={player.nickname}
            className="w-24 h-24 rounded-full border-4 border-red-500"
          />
          <h2 className="text-2xl font-bold text-white">{player.nickname}</h2>
          <div className="flex items-center space-x-2">
            <img
              alt={`Level ${player.level}`}
              src={levelImages[player.level]}
              className="w-6 h-6"
            />
            <span className="text-gray-300">{player.elo} ELO</span>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full max-w-2xl mt-6">
          <StatCard
            label="Weekly Points"
            value={`${player.weeklyElo > 0 ? "+" : ""}${player.weeklyElo}`}
            className={player.weeklyElo > 0 ? "text-green-500" : "text-red-500"}
          />
          <StatCard
            label="Win/Loss"
            value={`${player.weeklyWins}/${player.weeklyLosses}`}
            className="text-gray-300"
          />
          <StatCard
            label="Win Rate"
            value={`${player.winRate}%`}
            className="text-gray-300"
          />
          <StatCard
            label="Matches"
            value={player.matches}
            className="text-gray-300"
          />
        </div>

        {/* Recent Results */}
        <div className="mt-6">
          <h3 className="text-gray-400 text-sm mb-2">Recent Results</h3>
          <div className="flex space-x-2">
            {player.recentResults.map((result, index) => (
              <div
                key={index}
                className={`w-4 h-4 rounded-full ${
                  result === "1" ? "bg-green-500" : "bg-red-500"
                }`}
              />
            ))}
          </div>
        </div>

        {/* Shame Badge */}
        <div className="mt-6 bg-red-500/10 rounded-lg p-4 text-center">
          <span className="text-red-500 text-lg font-semibold">
            💩 Shit player of the week
          </span>
        </div>
      </div>
    </div>
  );
}

// Helper component for stats
function StatCard({ label, value, className }) {
  return (
    <div className="bg-gray-700 rounded-lg p-4 text-center">
      <div className="text-sm text-gray-400 mb-1">{label}</div>
      <div className={`text-lg font-semibold ${className}`}>{value}</div>
    </div>
  );
}

export default HallOfShamePlayer;
